import { getTimeZones } from '@vvo/tzdb'
import { cx } from 'class-variance-authority'
import differenceInDays from 'date-fns/differenceInDays'
import formatDistance from 'date-fns/formatDistance'
import isSameYear from 'date-fns/isSameYear'
import subDays from 'date-fns/subDays'
import { formatInTimeZone } from 'date-fns-tz'

export const FALLBACK_TIMEZONE = 'America/Chicago'

export const daysAgo = (date: Date) => {
  return differenceInDays(new Date(), new Date(date))
}

export const timeAgo = (date: Date | string) => {
  return formatDistance(new Date(), new Date(date))
}

export const TimeAgo = ({ date, className }: { date?: Date | string | null; className?: string }) => {
  if (!date) {
    return null
  }
  return <span className={cx('text-sm text-gray-600', className)}>{timeAgo(date)} ago</span>
}

export const relativeTime = (date: Date | string | number) => {
  const d1 = new Date()
  const d2 = new Date(date)
  let postfix = 'ago'
  if (d2 > d1) {
    postfix = 'from now'
  }
  return formatDistance(new Date(), new Date(date)) + ' ' + postfix
}

export const RelativeTime = ({ date }: { date?: Date | string | number | null }) => {
  if (!date) {
    return null
  }

  return <span title={date.toLocaleString('en-US')}>{relativeTime(date)}</span>
}

export const toDatepickerString = (date: Date | string, timezone: string) => {
  return formatInTimeZone(date, timezone, "yyyy-MM-dd'T'HH:mm")
}

export const formatDateAndTime = (date: Date, timezone: string, displayTimezone?: boolean) => {
  const formatString = 'MMM. dd, yyyy h:mmaaa' + (displayTimezone ? ' zzz' : '')

  const formattedDate = formatInTimeZone(date, timezone, formatString)

  // No need to add a period after the month of May, which is short enough on its own
  const sanitizedDateString = formattedDate.replace('May.', 'May')

  return sanitizedDateString
}

/**
 * This function formats the date in a human friendly way that takes server side rendering into account.
 * If we were to just rely on `date-fns/formatRelative`, we would get different results on the server and client.
 */
export const formatHumanFriendlyDate = (date: Date, timezone: string, relative = true) => {
  if (relative) {
    const today = formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd')
    const yesterday = formatInTimeZone(subDays(new Date(), 1), timezone, 'yyyy-MM-dd')
    const tomorrow = formatInTimeZone(subDays(new Date(), -1), timezone, 'yyyy-MM-dd')

    const dateStr = formatInTimeZone(date, timezone, 'yyyy-MM-dd')

    if (dateStr === today) {
      return 'today'
    }

    if (dateStr === tomorrow) {
      return 'tomorrow'
    }

    if (dateStr === yesterday) {
      return 'yesterday'
    }

    if (isSameYear(date, new Date())) {
      return formatInTimeZone(date, timezone, 'MMM. dd').replace('May.', 'May')
    }
  }

  return formatInTimeZone(date, timezone, 'MMM. dd, yyyy').replace('May.', 'May')
}

export const formatHumanFriendlyTime = (date: Date, timezone: string) => {
  return formatInTimeZone(date, timezone, 'hh:mmaaa')
}

export const formatHumanFriendlyDateAndTime = (date: Date, timezone: string, separator?: string, relative = true) => {
  return formatHumanFriendlyDate(date, timezone, relative) + (separator ?? ' at ') + formatHumanFriendlyTime(date, timezone)
}

export const formatHumanFriendDateAndTimeWithDivider = (date: Date, timezone: string) => {
  return (
    <span className='flex gap-2'>
      {formatHumanFriendlyDate(date, timezone)} <span className='text-gray-200'>|</span>{' '}
      <span>{formatHumanFriendlyTime(date, timezone)}</span>
    </span>
  )
}

export const getSupportedTimezones = () => {
  return getTimeZones().map((tz) => {
    const offset = tz.currentTimeOffsetInMinutes / 60
    const prefix = offset > 0 ? '+' : '-'

    return {
      value: tz.name,
      label: `(GMT${prefix}${Math.abs(offset)}) ${tz.name}`,
      offset
    }
  })
}

export const formatCountdown = (remainingMs: number) => {
  const totalSeconds = Math.floor(remainingMs / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}
